/*************************************************************
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 *                                                           *
 * This was generated by `dabu-web-api/src/codes-sync.sh`.   *
 *                                                           *
 * Please edit `dabu-web-api/src/codes.txt` and run the sync *
 * script.                                                   *
 *                                                           *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 *************************************************************/

const svHttpCodes = {
    // Reserved
    RC_E_NO_BODY: 2999,
    RC_E_MALFORMED: 2998,
    RC_E_RATELIMIT: 2997,

    // POST /api/u/v1/signup
    RC_U_SIGNUP: 1000,
    RC_E_U_SIGNUP_PW_WEAK: 2000,
    RC_E_U_SIGNUP_MIN_FULLNAME: 2001,
    RC_E_U_SIGNUP_MAX_FULLNAME: 2002,
    RC_E_U_SIGNUP_BAD_FULLNAME: 2003,
    RC_E_U_SIGNUP_EMAIL_EXISTS: 2004,

    // POST /api/u/v1/login
    RC_U_LOGIN: 1000,
    RC_E_U_LOGIN_NOT_FOUND: 2000,
    RC_E_U_LOGIN_INACTIVE: 2001,
    RC_E_U_LOGIN_BANNED: 2002,

    // GET /api/u/v1/oauth2_login_url
    RC_U_OAUTH2_LOGIN_URL: 1000,

    // POST /api/u/v1/oauth2_login
    RC_U_OAUTH2_LOGIN: 1000,
    RC_E_U_OAUTH2_LOGIN_ACCESS_TOKEN: 2000,
    RC_E_U_OAUTH2_LOGIN_USER_INFO: 2001,

    // POST /api/u/v1/logout
    RC_U_LOGOUT: 1000,
    RC_E_U_LOGOUT_NOT_LOGIN: 2000,

    // POST /api/u/v1/fullname_change
    RC_U_FULLNAME_CHANGE: 1000,
    RC_E_U_FULLNAME_CHANGE_MIN: 2000,
    RC_E_U_FULLNAME_CHANGE_MAX: 2001,
    RC_E_U_FULLNAME_CHANGE_BAD: 2002,

    // POST /api/u/v1/pw_change
    RC_U_PW_CHANGE: 1000,
    RC_E_U_PW_CHANGE_NEW_PW_WEAK: 2000,
    RC_E_U_PW_CHANGE_NO_USER: 2001,
    RC_E_U_PW_CHANGE_INVALID_OLD_PW: 2002,

    // POST /api/u/v1/pw_reset
    RC_U_PW_RESET: 1000,

    // POST /api/u/v1/pw_reset/verify
    RC_U_PW_RESET_VERIFY: 1000,
    RC_E_U_PW_RESET_VERIFY_PW_WEAK: 2000,
    RC_E_U_PW_RESET_VERIFY_EXPIRED: 2001,
    RC_E_U_PW_RESET_VERIFY_INVALID_TOKEN: 2002,
    RC_E_U_PW_RESET_VERIFY_NO_USER: 2003,

    // POST /api/u/v1/signup_verify
    RC_U_SIGNUP_VERIFY: 1000,
    RC_E_U_SIGNUP_VERIFY_FAILED: 2000,

    // POST /api/u/v1/email_change
    RC_U_EMAIL_CHANGE: 1000,
    RC_E_U_EMAIL_CHANGE_EMAIL_EXISTS: 2000,

    // POST /api/u/v1/email_change/verify
    RC_U_EMAIL_CHANGE_VERIFY: 1000,
    RC_E_U_EMAIL_CHANGE_EMAIL_TAKEN: 2000,
    RC_E_U_EMAIL_CHANGE_INVALID_TOKEN: 2001,
    RC_E_U_EMAIL_CHANGE_VERIFY_EXPIRED: 2002,

    // POST /api/dabu/v1/sub_upd
    RC_DABU_SUBSCRIPTION_UPDATE: 1000,
    RC_E_DABU_SUBSCRIPTION_UPDATE_NO_PM: 2000,
    RC_E_DABU_SUBSCRIPTION_UPDATE_PM_ISSUE: 2001,

    // GET /api/billing/v1/stripe/pm_cs
    RC_BILLING_STRIPE_PM_CHECKOUT_SESSION: 1000,

    // POST /api/u/v1/delete
    RC_U_DELETE: 1000,

    // POST /api/u/v1/profile_image_upload
    RC_U_PROFILE_IMG_UPLOAD: 1000,

    // POST /api/u/v1/profile_details
    RC_U_PROFILE_DETAILS: 1000,

    // POST /api/u/v1/new_ad
    RC_U_NEW_ADD: 1000,
    RC_E_U_NEW_ADD_LIMIT_REACHED: 2000,
};

declare global {
    var httpCodes: typeof svHttpCodes;
}

globalThis.httpCodes = svHttpCodes;

export default svHttpCodes;
