const webEnv = {
    apiBaseUrl: process.env.API_BASE_URL!,
    webBaseUrl: process.env.WEB_BASE_URL!,
    stripeKey: process.env.STRIPE_PUBLIC_KEY!,
    recaptchaKey: process.env.RECAPTCHA_KEY!,
};

declare global {
    var env: typeof webEnv;
}

globalThis.env = webEnv;

export default webEnv;
