import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface userDetailsState {
    fullname?: string;
    email?: string;
    signup_verified?: boolean;
    pw_exists?: boolean;
    plan?: string;
    reqMade?: number;
    reqTotal?: number;
    profileImgUrl?: string;
}

const initialState: userDetailsState = {
    fullname: '',
    email: '',
    signup_verified: false,
    pw_exists: false,
    plan: 'Free',
    reqMade: 0,
    reqTotal: 0,
    profileImgUrl: '',
};

interface UpdatePayload {
    fullname?: string;
    email?: string;
    signup_verified?: boolean;
    pw_exists?: boolean;
    plan?: string;
    reqMade?: number;
    reqTotal?: number;
    profileImgUrl?: string;
}

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<UpdatePayload>) => {
            const { fullname, email, signup_verified, pw_exists, plan, reqMade, reqTotal, profileImgUrl } =
                action.payload;

            if (fullname) {
                state.fullname = fullname;
            }
            if (email) {
                state.email = email;
            }
            if (signup_verified) {
                state.signup_verified = signup_verified;
            }
            if (pw_exists) {
                state.pw_exists = pw_exists;
            }
            if (plan) {
                state.plan = plan;
            }
            if (reqMade || reqMade === 0) {
                state.reqMade = reqMade;
            }
            if (reqTotal) {
                state.reqTotal = reqTotal;
            }
            if (profileImgUrl) {
                state.profileImgUrl = profileImgUrl;
            }
        },
        reset: (state, _: PayloadAction<UpdatePayload>) => {
            return initialState;
        },
    },
});

export const { update, reset } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
