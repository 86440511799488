import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { store, persistor } from 'src/redux/store.ts';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const ErrorBoundary = lazy(() => import('src/components/ErrorBoundary.tsx'));

import httpCodes from 'src/httpCodes.ts';
import env from 'src/env.ts';

const App = lazy(() => import('src/App.tsx'));
global.env = env;
global.httpCodes = httpCodes;

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Suspense>
                <PersistGate loading={null} persistor={persistor}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </PersistGate>
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>
);
